import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer";

import HomePage from "./pages/homepage/homepage";
import AboutPage from "./pages/about-page/about-page";
import ProductsPage from "./pages/products-page/productsPage";
import TechnologyPage from "./pages/technology-page/technology-page";
import ContactPage from "./pages/contact-page/contact-page";
import NotFoundPage from "./pages/not-found/NotFoundPage";

import Logo from "./assets/img/logo-white.svg";

const App = () => {
  return (
    <div>
      <Header
        brand="Plus-Klima"
        rightLinks={<HeaderLinks />}
        fixed
        color={window.location.pathname === "/" ? "transparent" : "white"}
        imgDefaultColor={window.location.pathname !== "/" ? "decolorify" : ""}
        imgSrc={Logo}
        changeColorOnScroll={
          window.location.pathname === "/"
            ? {
                height: 200,
                color: "white",
                imgColor: "decolorify"
              }
            : null
        }
      />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/rreth-nesh" component={AboutPage} />
        <Route path="/produkte/:seria" component={ProductsPage} />
        <Route path="/teknologjia-mitsubishi" component={TechnologyPage} />
        <Route path="/kontakt" component={ContactPage} />
        <Route exact path="/not-found" component={NotFoundPage} />
        <Redirect to="/not-found" />
      </Switch>
      <Footer />
    </div>
  );
};

export default withRouter(App);
