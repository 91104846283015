import React from "react";

import InfoArea from "components/InfoArea/InfoArea.js";

// SVGs
import Security from "@material-ui/icons/Security";
import Healing from "@material-ui/icons/Healing";
import Stars from "@material-ui/icons/Stars";

import "./InfoArea.css";

const InfoAreaSection = () => (
  <div className="infoarea-container">
    <InfoArea
      title="Cilësi"
      description="Mitsubishi Electric është krijuar me qëllimin e vetëm që të plotësojë të gjitha nevojat e klientit me performancën më të lartë."
      icon={Stars}
      iconColor="danger"
    />
    <InfoArea
      title="Siguri"
      description="Produktet Mitsubishi Electric integrojnë teknologjinë Japoneze duke mbajtur familjen tuaj larg agjentëve bakterialë dhe alergjikë."
      icon={Healing}
      iconColor="success"
    />
    <InfoArea
      title="Garanci"
      description="Të gjitha produktet Mitsubishi Electric shoqërohen me garanci 5-vjeçare."
      icon={Security}
      iconColor="info"
    />
  </div>
);

export default InfoAreaSection;
