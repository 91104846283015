import React from "react";
import PropTypes from "prop-types";

import "./ProductDetailsTable.css";

const ProductDetailsTable = ({ content }) => (
  <table>
    <thead>
      <tr style={{ backgroundColor: "#dddddd" }}>
        <th>Karakteristika</th>
        <th>Vlera</th>
      </tr>
    </thead>

    <tbody>
      {content.map(({ type, data, styles }, index) =>
        data ? (
          <tr style={{ ...styles }} key={index}>
            <td>{type}</td>
            <td>{data}</td>
          </tr>
        ) : null
      )}
    </tbody>
  </table>
);

ProductDetailsTable.propTypes = {
  content: PropTypes.array
};

export default ProductDetailsTable;
