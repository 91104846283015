import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BigSpinner from "components/BigSpinner/BigSpinner";

const CategorySection = ({
  isLoading,
  families,
  family,
  handleFamilyChange
}) => (
  <div>
    <p>Kategoritë</p>
    <hr />
    {isLoading ? (
      <div>
        {families.map(({ familja, id }) => (
          <FormControlLabel
            key={id}
            id={id}
            value="end"
            control={
              <Checkbox
                checked={family.includes(`${id}`)}
                inputProps={{
                  "data-id": `${id}`
                }}
                onChange={event => handleFamilyChange(event)}
              />
            }
            label={familja}
            labelPlacement="end"
          />
        ))}
      </div>
    ) : (
      <div style={{ margin: "10px auto", minHeight: "100px" }}>
        <BigSpinner style={{ width: "20%" }} />
      </div>
    )}
  </div>
);

CategorySection.propTypes = {
  families: PropTypes.array,
  handleFamilyChange: PropTypes.func,
  family: PropTypes.array,
  isLoading: PropTypes.bool
};

export default CategorySection;
