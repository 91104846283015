import React from "react";
import PropTypes from "prop-types";

import CustomInput from "components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

const FilterSearch = ({ handleInputChange, searchInput }) => (
  <div>
    <p>Kërkimi</p>
    <hr />
    <CustomInput
      labelText="Kërko një produkt"
      id="material"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        name: "searchInput",
        value: searchInput,
        type: "text",
        onChange: event => handleInputChange(event),
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        )
      }}
    />
  </div>
);

FilterSearch.propTypes = {
  searchInput: PropTypes.string,
  handleInputChange: PropTypes.func
};

export default FilterSearch;
