import React from "react";

import Slider from "assets/img/slider.png";

import "./FilterTitle.css";

const FilterTitle = () => (
  <div className="products-filter-title">
    <img src={Slider} alt="slider-logo" width="22px" height="auto" />
    <h5>Filtrimi i rezultateve</h5>
  </div>
);

export default FilterTitle;
