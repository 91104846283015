import React from "react";
import PropTypes from "prop-types";

import "./ImageAndInfoDetailsOverlay.css";

const ImageAndInfoDetailsOverlay = ({ children }) => (
  <div className="image-and-info-details">{children}</div>
);

ImageAndInfoDetailsOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ImageAndInfoDetailsOverlay;
