import FormActionTypes from "./form.types";
import { DB } from "../../api/DB";
import ValidateForm from "./form.utils";

export const handleFormChange = event => ({
  type: FormActionTypes.CHANGE_FORM_INPUT,
  payload: {
    target: event.target.name,
    value: event.target.value
  }
});

export const postFormStart = () => ({
  type: FormActionTypes.POST_FORM_START
});

export const postFormSuccess = () => ({
  type: FormActionTypes.POST_FORM_SUCCESS,
  payload: "Kërkesa juaj u dërgua me sukses"
});

export const postFormFailure = errorMessage => ({
  type: FormActionTypes.POST_FORM_FAILURE,
  payload: errorMessage
});

export const postFormStartAsync = mailData => {
  return dispatch => {
    dispatch(postFormStart());

    ValidateForm(mailData)
      .then(mailData => {
        DB.Form.sendMail(mailData).then(info => {
          console.log(info);
          if (info.errno || info.code) {
            dispatch(
              postFormFailure({
                email: undefined,
                body: undefined,
                other: "*Kërkesa juaj nuk u dërgua. Ju lutem provoni përsëri!"
              })
            );
          } else {
            dispatch(postFormSuccess());
          }
        });
      })
      .catch(error => dispatch(postFormFailure(error)));
  };
};
