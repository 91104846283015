import React from "react";
import { Helmet } from "react-helmet";
// nodejs library that concatenates classes
import classNames from "classnames";
import canUseWebP from "helpers/canUseWebP";
// react components for routing our app without refresh
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
//import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
//import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Solutions from "components/Solutions/Solutions";
import Button from "components/CustomButtons/Button";

import Fade from "react-reveal/Fade";
import ZonedComfort from "components/ZonedComfort/ZonedComfort";

import InfoAreaSection from "./sections/InfoArea";
import InfoImageSection from "./sections/InfoImage";

const useStyles = makeStyles(styles);

function HomePage({ history }) {
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="description"
          content="Plus-Klima, një kompani elitare në sistemet e kondicionimit ofron produkte Mitsubishi të certifikuara me garanci 5-vjeçare. Eksperiencë 20 vjeçare në treg që prej 1999. Kondicionere Mitsubishi të teknologjisë inverter. Njihuni me teknologjinë më të fundit Mitsubishi si dhe produktet tona. Mitsubishi Electric Tiranë."
        />
        <meta
          name="keywords"
          content="plus, klima, kondicionere, kondicionere mitsubishi electric, kondicionere mitsubishi, kondicioner inverter, inverter, kondicioner mitsubishi, kondicioner te perdorur, montim, servis kondicioneresh, kondicioner per shitje, kondicionere tirane, uni, klima, uni-klima, kondicioner muror, kondicioner dysheme, kondicioner tavanor, globe kondicioner, kondicioner, kondicionere neptun, kondicioner 18, kondicioner 24, neptun, megatek, aza electronics, msz, mfz, sez, slz, a+++"
        />
        <title>Kondicionerë Mitsubishi | Faqja kryesore | Plus-Klima</title>
      </Helmet>
      <Parallax
        image={
          canUseWebP()
            ? require("assets/img/webp/bg4.webp")
            : require("assets/img/bg4.png")
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>Seritë e reja Mitsubishi.</h1>
                <h3 className={classes.subtitle}>
                  Përfito performancën më të lartë dhe kompaktësinë në një njësi
                  të vetme.
                </h3>
                <Button
                  color="danger"
                  size="lg"
                  style={{ marginTop: "20px" }}
                  onClick={() => history.push("/produkte/seria-m")}
                >
                  Shiko Seritë
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div
        style={{ width: "96vw", margin: "-70px auto" }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <Solutions />
        <Fade bottom>
          <InfoAreaSection />
        </Fade>

        <InfoImageSection />

        <Fade bottom>
          <ZonedComfort />
        </Fade>
      </div>
    </div>
  );
}

HomePage.propTypes = {
  history: PropTypes.object
};

export default withRouter(HomePage);
