import canUseWebP from "../../helpers/canUseWebP";

import timeline1999 from "../../assets/img/timeline-1999.png";
import timeline2005 from "../../assets/img/timeline-2005.png";
import timeline2010 from "../../assets/img/timeline-2010.png";
import timeline2015 from "../../assets/img/timeline-2015.png";
import timeline2019 from "../../assets/img/timeline-2019.png";

import timeline1999WebP from "../../assets/img/webp/timeline-1999.webp";
import timeline2005WebP from "../../assets/img/webp/timeline-2005.webp";
import timeline2010WebP from "../../assets/img/webp/timeline-2010.webp";
import timeline2015WebP from "../../assets/img/webp/timeline-2015.webp";
import timeline2019WebP from "../../assets/img/webp/timeline-2019.webp";

let timelineData;

if (canUseWebP()) {
  timelineData = [
    {
      dataText: "Krijimi",
      year: "1999",
      imgSrc: timeline1999WebP,
      content:
        'Ne krijuam "Plus-Klima" në vitin 1999 me vizionin për të sjellë në familjet tuaja produktet e një klasi të lartë dhe të njohur në mbarë botën. Ndër kompanitë e para në sistemet e kondicionimit në Shqipëri, "Plus-Klima" nisi me sukses tregtimin e produkteve Mitsubishi Electric.'
    },
    {
      dataText: "Arritje",
      year: "2005",
      imgSrc: timeline2005WebP,
      content:
        "Për herë të parë arrijmë krijimin e një ekspozite produktesh Mitsubishi Electric 'Made in Japan'. Të pranishëm në ambientet më elitare sërish vazhdojmë të shërbejmë dhe qëndrojmë pranë jush."
    },
    {
      dataText: "10,000 njësi të vendosura",
      year: "2010",
      imgSrc: timeline2010WebP,
      content:
        "Në vitin 2010 suksesi kurorëzohet me 10,000 njësi të vendosura në Shqipëri. Një moment mjaft i veçantë që dëshmon besueshmërinë tuaj dhe cilësinë e produkteve Mitsubishi Electric."
    },
    {
      dataText: "Përtej kufijve",
      year: "2015",
      imgSrc: timeline2015WebP,
      content:
        "Arrijmë të gjendemi dhe përtej kufijve Shqiptarë: në Kosovë, Mal të Zi, Maqedoni dhe më gjerë. Produktet e garantuara nën markën Mitsubishi Electric bëhen të njohura për çdo familje."
    },
    {
      dataText: "E tashmja dhe e ardhmja",
      year: "2019",
      imgSrc: timeline2019WebP,
      content:
        "Arrijmë mbi 17,000 njësi të shitura duke u bërë lider në sistemet më moderne të konicionimit, sisteme Multi-Split dhe ato VRF. Historia e suksesit vazhdon falë jush..."
    }
  ];
} else {
  timelineData = [
    {
      dataText: "Krijimi",
      year: "1999",
      imgSrc: timeline1999,
      content:
        'Ne krijuam "Plus-Klima" në vitin 1999 me vizionin për të sjellë në familjet tuaja produktet e një klasi të lartë dhe të njohur në mbarë botën. Ndër kompanitë e para në sistemet e kondicionimit në Shqipëri, "Plus-Klima" nisi me sukses tregtimin e produkteve Mitsubishi Electric.'
    },
    {
      dataText: "Arritje",
      year: "2005",
      imgSrc: timeline2005,
      content:
        "Për herë të parë arrijmë krijimin e një ekspozite produktesh Mitsubishi Electric 'Made in Japan'. Të pranishëm në ambientet më elitare sërish vazhdojmë të shërbejmë dhe qëndrojmë pranë jush."
    },
    {
      dataText: "10,000 njësi të vendosura",
      year: "2010",
      imgSrc: timeline2010,
      content:
        "Në vitin 2010 suksesi kurorëzohet me 10,000 njësi të vendosura në Shqipëri. Një moment mjaft i veçantë që dëshmon besueshmërinë tuaj dhe cilësinë e produkteve Mitsubishi Electric."
    },
    {
      dataText: "Përtej kufijve",
      year: "2015",
      imgSrc: timeline2015,
      content:
        "Arrijmë të gjendemi dhe përtej kufijve Shqiptarë: në Kosovë, Mal të Zi, Maqedoni dhe më gjerë. Produktet e garantuara nën markën Mitsubishi Electric bëhen të njohura për çdo familje."
    },
    {
      dataText: "E tashmja dhe e ardhmja",
      year: "2019",
      imgSrc: timeline2019,
      content:
        "Arrijmë mbi 17,000 njësi të shitura duke u bërë lider në sistemet më moderne të konicionimit, sisteme Multi-Split dhe ato VRF. Historia e suksesit vazhdon falë jush..."
    }
  ];
}

export default timelineData;
