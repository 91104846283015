import HTTP from "./HTTP";

export default class Form extends HTTP {
  constructor() {
    super();

    this.apiPath = "/send-mail";
    this.url = this.getURL();
  }

  getURL() {
    return `${this.baseURL}${this.apiPath}`;
  }

  sendMail(data) {
    return this.post(`${this.url}`, data);
  }
}
