import React from "react";
import PropTypes from "prop-types";

import "./ProductDetailsImage.css";

const ProductDetailsImage = ({ imgSrc }) => (
  <div className="product-details-image">
    <div className="product-details-image-wrapper">
      <img src={imgSrc} alt="produkti" className="product-details-img" />
      <img src={imgSrc} alt="produkti" className="product-details-img-small" />
    </div>
  </div>
);

ProductDetailsImage.propTypes = {
  imgSrc: PropTypes.string
};

export default ProductDetailsImage;
