import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

import ShopComponent from "components/ShopComponent/ShopComponent";
import ProductDetails from "pages/product-details/product-details";

import "./ProductsPage.css";

const ProductsPage = ({ match }) => {
  return (
    <div>
      <Route exact path={`${match.path}`} component={ShopComponent} />
      <Route path={`${match.path}/:modeli`} component={ProductDetails} />
    </div>
  );
};

ProductsPage.propTypes = {
  match: PropTypes.object
};

export default ProductsPage;
