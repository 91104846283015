import React from "react";
import classNames from "classnames";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";

import NavPills from "components/NavPills/NavPills.js";
import Quote from "components/Typography/Quote.js";
import Timeline from "../../components/Timeline/Timeline";
import InfoAreaSection from "./sections/InfoArea";
import Background from "components/Background/Background";
import canUseWebP from "helpers/canUseWebP";

// SVGs
import MeetingRoom from "@material-ui/icons/MeetingRoom";
import Build from "@material-ui/icons/Build";
import Security from "@material-ui/icons/Security";

import styles from "assets/jss/material-kit-react/views/components.js";

import "./about-page.styles.css";

const useStyles = makeStyles(styles);

const AboutPage = ({ history }) => {
  const classes = useStyles();
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="description"
          content="Kondicionerë me garanci 5-vjeçare. Plus-Klima ofron montime, riparime dhe servis të produkteve Mitsubishi. Mitsubishi Electric Tiranë."
        />
        <meta
          name="keywords"
          content="kondicionere, kondicioner, kondicionere mitsubishi electric tirana, kondicionere mitsubishi, kondicioner inverter, kondicioner mitsubishi, kondicioner te perdorur, kondicioner mitsubishi ne tirane, montim, servis kondicioneresh, kondicioner per shitje, kondicionere tirane, uni, klima, kondicionere muror, kondicionere dysheme, kondicionere tavanor, kondicionere te mire, globe kondicioner"
        />
        <title>
          Montime dhe Riparime Kondicionerësh | Rreth nesh | Plus-Klima
        </title>
      </Helmet>
      <Background
        imgSrc={
          canUseWebP()
            ? require("assets/img/webp/bg5.webp")
            : require("assets/img/bg5.png")
        }
        otherClasses="banner"
        title="Ne mundësojmë komoditetin tuaj."
        subtitle="Me Plus-Klima ju do arrini maksimumin e komfortit në shtëpinë
                  tuaj."
        titleStyles={{ color: "#BB5D99" }}
        subtitleStyles={{ color: "#777777" }}
      />

      <div
        style={{ width: "96vw", margin: "-70px auto" }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div className="about-page-navpills">
          <NavPills
            color="rose"
            alignCenter
            tabs={[
              {
                tabButton: "Montime",
                tabIcon: MeetingRoom,
                tabContent: (
                  <span>
                    <Quote
                      text="Ne ofrojmë montime dhe instalime të pajisjeve në sisteme
                      Mono dhe Multi-Split. Puna e kujdesshme e punonjësve tanë
                      përmes dhe kompaktësisë së pajisjeve Mitsubishi ruajnë
                      elegancën dhe finesën e dekorit të ambientit tuaj. Duke
                      dhënë sërish maksimumimin e performancës, ambienti juaj do
                      të jetë mjaft komod. Nëse kërkoni perfeksionin atëherë
                      zgjidhja juaj e vetme është Plus-Klima."
                    />
                  </span>
                )
              },
              {
                tabButton: "Riparime",
                tabIcon: Build,
                tabContent: (
                  <span>
                    <Quote
                      text="Ne gjendemi gjithmonë pranë jush. Për çdo problem me
                      pajisjen tuaj, Plus-Klima do të vijë menjëherë për ta
                      rregulluar. Puna e palodhur na ka lejuar ti përgjigjemi të
                      gjitha kërkesave të klientëve dhe të renditemi ndër
                      kompanitë më të preferuara. Shërbimi kryhet brenda 24
                      orëve."
                    />
                  </span>
                )
              },
              {
                tabButton: "Garanci 5-vjeçare",
                tabIcon: Security,
                tabContent: (
                  <span>
                    <Quote
                      text="Nëse kërkoni një zgjidhje afatgjatë për ambientin tuaj
                      atëherë zgjidhja e vetme janë pajisjet Mitsubishi.
                      Plus-Klima garanton autenticitetin e produkteve Mitsubishi
                      duke dhënë një garanci 5-vjeçare për të gjitha pajisjet."
                    />
                  </span>
                )
              }
            ]}
          />
        </div>
        <Timeline />
        <Fade bottom>
          <InfoAreaSection />
        </Fade>
        <Fade bottom>
          <div style={{ marginLeft: "4px", marginRight: "5px" }}>
            <Button
              size="lg"
              fullWidth
              color="info"
              onClick={() => history.push("/kontakt")}
            >
              Kontakto tani!
            </Button>
          </div>
        </Fade>
      </div>
    </div>
  );
};

AboutPage.propTypes = {
  history: PropTypes.object
};

export default withRouter(AboutPage);
