import React from "react";
import { Helmet } from "react-helmet";

import Form from "components/Form/Form";
import LogoVertical from "assets/img/logo-vertical.svg";

import "./contact-page.css";

const ContactPage = () => (
  <div className="contact-page-container">
    <Helmet>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="description"
        content="Kondicionere Mitsubishi me garanci 5-vjeçare. Kontaktoni Plus-Klima për shërbimin më të shpejtë në sistemet e kondicionimit dhe aspirimit."
      />
      <meta
        name="keywords"
        content="kondicionere, kondicionere mitsubishi electric tirana, kondicionere mitsubishi, kondicioner inverter, kondicioner mitsubishi, kondicioner te perdorur, kondicioner mitsubishi ne tirane, montim, servis kondicioneresh, kondicioner per shitje, kondicionere tirane, uni, klima, kondicionere muror, kondicionere dysheme, kondicionere tavanor, kondicionere te mire, globe kondicioner, kondicionere daikin, kontakt"
      />
      <title>Kontakt | Plus-Klima</title>
    </Helmet>
    <div>
      <Form />
    </div>
    <div className="contact-page-info">
      <img src={LogoVertical} alt="logo-vertical" width="75%" height="auto" />
      <h5 style={{ textAlign: "center" }}>
        Adresa: Rruga e Kavajës pranë Parkut të Autobusave, Tiranë 1001
      </h5>
      <h5>
        Celular:{" "}
        <span>
          <a style={{ color: "rgb(58, 143, 222)" }} href="tel:+355692181069">
            +355 69 21 81 069
          </a>
        </span>
      </h5>
    </div>
  </div>
);

export default ContactPage;
