import React from "react";
import PropTypes from "prop-types";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import ProductDetailsTable from "components/ProductDetailsTable/ProductDetailsTable";

const ProductDeatilsTableSection = ({ productDetails }) => (
  <div style={{ marginTop: "60px" }}>
    <CustomTabs
      headerColor="info"
      tabs={[
        {
          tabName: "Detajet e produktit",
          tabContent: (
            <ProductDetailsTable
              content={[
                {
                  type: "Tensioni (V)",
                  data: productDetails.tensioni
                },
                {
                  type: "Lloji i gazit",
                  data: productDetails.gazi
                },
                {
                  type:
                    "Niveli i zhurmës së pajisjes së brendshme (min-max) (dB(A))",
                  data: productDetails.niveli_zhurmes
                },
                {
                  type: "Sipërfaqja që mbulon (m²)",
                  data: productDetails.sip
                },
                {
                  type: "Dimensionet (lartësi-gjerësi-thellësi) (mm)",
                  data: productDetails.permasa
                },
                {
                  type: "Kapaciteti i ngrohjes (kW)",
                  data:
                    productDetails.kapaciteti_n ||
                    "Në varësi të pajisjes së jashtme",
                  styles: { backgroundColor: "rgba(219, 90, 81, 0.2)" }
                },
                {
                  type: "Kapaciteti i ngrohjes (min-max) (kW)",
                  data:
                    productDetails.konsumi_en_n === "0"
                      ? "Në varësi të pajisjes së jashtme"
                      : productDetails.konsumi_en_n,
                  styles: { backgroundColor: "rgba(219, 90, 81, 0.2)" }
                },
                {
                  type: "Klasi i energjisë (ngrohje)",
                  data:
                    productDetails.klasi_n === "0"
                      ? "Në varësi të pajisjes së jashtme"
                      : productDetails.klasi_n,
                  styles: { backgroundColor: "rgba(219, 90, 81, 0.2)" }
                },
                {
                  type: "Kapaciteti i ftohjes (kW)",
                  data:
                    productDetails.kapaciteti_f ||
                    "Në varësi të pajisjes së jashtme",
                  styles: { backgroundColor: "rgba(80, 128, 217, 0.2)" }
                },
                {
                  type: "Kapaciteti i ftohjes (min-max) (kW)",
                  data:
                    productDetails.konsumi_en_f === "0"
                      ? "Në varësi të pajisjes së jashtme"
                      : productDetails.konsumi_en_f,

                  styles: { backgroundColor: "rgba(80, 128, 217, 0.2)" }
                },
                {
                  type: "Klasi i energjisë (ftohje)",
                  data:
                    productDetails.klasi_f === "0"
                      ? "Në varësi të pajisjes së jashtme"
                      : productDetails.klasi_f,

                  styles: { backgroundColor: "rgba(80, 128, 217, 0.2)" }
                }
              ]}
            />
          )
        }
      ]}
    />
  </div>
);

ProductDeatilsTableSection.propTypes = {
  productDetails: PropTypes.object
};

export default ProductDeatilsTableSection;
