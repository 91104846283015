import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const CapacitySection = ({ reduxStateCapacity, handleCapacityChange }) => (
  <div>
    <p>Kapaciteti (BTU)</p>
    <hr />
    {[9000, 12000, 18000, 24000].map(capacity => (
      <FormControlLabel
        key={capacity}
        id={capacity}
        control={
          <Checkbox
            checked={reduxStateCapacity.includes(`${capacity}`)}
            inputProps={{
              "data-id": `${capacity}`
            }}
            onChange={event => handleCapacityChange(event)}
          />
        }
        label={capacity}
        labelPlacement="end"
      />
    ))}
  </div>
);

CapacitySection.propTypes = {
  reduxStateCapacity: PropTypes.array,
  handleCapacityChange: PropTypes.func
};

export default CapacitySection;
