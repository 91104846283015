import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./NotFoundPage.scss";

const NotFoundPage = () => (
  <div id="notfound">
    <div className="notfound">
      <div className="notfound-404">
        <div></div>
        <h1>404</h1>
      </div>
      <h2>Faqja nuk u gjet</h2>
      <p>
        Faqja që keni kërkuar nuk ekziston ose është përkohësisht e paarritshme.
      </p>
      <Link to="/">Kthehu në faqen kryesore</Link>
    </div>
  </div>
);

NotFoundPage.propTypes = {
  history: PropTypes.object
};

export default NotFoundPage;
