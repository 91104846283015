import { createSelector } from "reselect";

const selectFilter = state => state.filter;

export const selectFamiliesItems = createSelector(
  [selectFilter],
  filterData => filterData.families
);

export const selectSearchInput = createSelector(
  [selectFilter],
  filterData => filterData.searchInput
);

export const selectColor = createSelector(
  [selectFilter],
  filterData => filterData.color
);

export const selectPriceFrom = createSelector(
  [selectFilter],
  filterData => filterData.priceFrom
);

export const selectPriceTo = createSelector(
  [selectFilter],
  filterData => filterData.priceTo
);

export const selectFamily = createSelector(
  [selectFilter],
  filterData => filterData.family
);

export const selectCurrentPage = createSelector(
  [selectFilter],
  filterData => filterData.page
);

export const selectCapacity = createSelector(
  [selectFilter],
  filterData => filterData.capacity
);

export const isFamilyLoaded = createSelector(
  [selectFilter],
  filterData => filterData.loaded
);

export const selectErrorMessage = createSelector(
  [selectFilter],
  filterData => filterData.loaded && filterData.errorMessage
);
