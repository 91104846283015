import React from "react";
import PropTypes from "prop-types";

import ProductDetailsImage from "components/ProductDetailsImage/ProductDetailsImage";
import ProductDetailsInfo from "components/ProductDetailsInfo/ProductDetailsInfo";
import ImageAndInfoDetailsOverlay from "../ImageAndInfoDetailsOverlay";
import canUseWebP from "helpers/canUseWebP";

const ImageAndInfoDetailsSection = ({ productDetails, familyDetails }) => (
  <ImageAndInfoDetailsOverlay>
    <ProductDetailsImage
      imgSrc={
        canUseWebP()
          ? `${process.env.PUBLIC_URL +
              "/" +
              productDetails.imazhi.split("/")[1] +
              "/webp/" +
              productDetails.imazhi.replace(".png", ".webp").split("/")[2]}`
          : process.env.PUBLIC_URL + productDetails.imazhi
      }
    />

    <ProductDetailsInfo
      model={productDetails.kodi_b}
      price={productDetails.cmimi_set || productDetails.cmimi_tek_ms}
      details={
        productDetails.familja_id === 20 || productDetails.familja_id === 9 ? (
          ""
        ) : productDetails.cmimi_set === 0 ||
          productDetails.cmimi_tek_ms === 0 ? (
          <p style={{ color: "rgb(201, 137, 26)" }}>
            Ky është çmimi vetëm për pajisjen e brendshme. Si model i një
            pajisjeje të jashtme mund të zgjidhet një model Mutli-Split.
          </p>
        ) : (
          `Në çmim përfshihet dhe modeli përkatës i pajisjes së jashtme ${productDetails.kodi_j}. Nëse si model i pajisjes së jashtme zgjidhet një model Multi-Split atëherë pajisja e brendshme shitet e veçuar dhe kushton ${productDetails.cmimi_tek_ms} €.`
        )
      }
      familyDetails={familyDetails.pershkrimi}
    />
  </ImageAndInfoDetailsOverlay>
);

ImageAndInfoDetailsSection.propTypes = {
  productDetails: PropTypes.object,
  familyDetails: PropTypes.object
};

export default ImageAndInfoDetailsSection;
