import React from "react";
import PropTypes from "prop-types";

import "./ProductDetailsInfo.css";

const ProductDetailsInfo = ({ model, price, details, familyDetails }) => (
  <div className="product-details-info-page">
    <h2>{model}</h2>
    <h3>{price} €</h3>

    <p style={{ color: "green" }}>Ky produkt është në gjendje.</p>
    <p>{details}</p>
    <p>{familyDetails}</p>
  </div>
);

ProductDetailsInfo.propTypes = {
  model: PropTypes.string,
  price: PropTypes.number,
  details: PropTypes.string,
  familyDetails: PropTypes.string
};

export default ProductDetailsInfo;
