import React from "react";
import PropTypes from "prop-types";

import Button from "components/CustomButtons/Button";

const ClearFiltersSection = ({ resetFilterToDefault }) => (
  <Button
    color="danger"
    fullWidth
    onClick={() => resetFilterToDefault()}
    style={{ margin: "30px auto" }}
  >
    Hiq filtrat
  </Button>
);

ClearFiltersSection.propTypes = {
  resetFilterToDefault: PropTypes.func
};

export default ClearFiltersSection;
