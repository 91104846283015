import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import ProductsWrapper from "components/ProductsWrapper/ProductsWrapper";
import PersistentDrawerLeft from "components/ProductsFilter/ProductsFilter";
import ReactPaginate from "react-paginate";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { selectTotalNumberOfPages } from "redux/product/product.selectors";
import { selectCurrentPage } from "redux/filter/filter.selectors";
import { changePage } from "redux/filter/filter.actions";

import SliderBlack from "assets/img/slider-black.png";

import "./ShopComponent.css";

const ShopComponent = ({
  totalPageCount,
  changePage,
  currentPage,
  match: { params }
}) => (
  <div>
    <Helmet>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta
        name="description"
        content="Kondicionerë me garanci 5-vjeçare. Produktet më të fundit Mitsubishi të teknologjisë inverter. Tek ne do të gjeni çmimet më të mira."
      />
      <meta
        name="keywords"
        content="kondicionere, kondicioner, kondicionere mitsubishi electric tirana, kondicionere mitsubishi, kondicioner inverter, kondicioner mitsubishi, kondicioner te perdorur, kondicioner mitsubishi ne tirane, montim, servis kondicioneresh, kondicioner per shitje, kondicionere tirane, uni, klima, kondicionere muror, kondicionere dysheme, kondicionere tavanor, kondicionere te mire, globe kondicioner, kondicionere 24, kondicionere 18"
      />
      <title>Kondicionerë Mitsubishi | Produkte | Plus-Klima</title>
    </Helmet>
    <div
      className="products-page"
      style={{ margin: "100px auto", marginBottom: "30px" }}
    >
      <div className="filter-mobile-expansion">
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div>
              <img
                src={SliderBlack}
                alt="slider-logo"
                width="27px"
                height="auto"
              />
              <h4 style={{ display: "inline-block", marginLeft: "15px" }}>
                Filtrimi
              </h4>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <PersistentDrawerLeft />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>

      <div className="products-breadcrumbs">
        <Paper elevation={0} style={{ padding: "10px" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textSecondary">Produkte</Typography>
            <Typography color="textSecondary">{`${params.seria.toUpperCase()}`}</Typography>
          </Breadcrumbs>
        </Paper>
      </div>

      <div className="filter-drawer">
        <PersistentDrawerLeft />
      </div>

      <ProductsWrapper />
    </div>
    <ReactPaginate
      pageCount={totalPageCount}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      onPageChange={pageObj => changePage(pageObj.selected + 1)}
      previousLabel="Para"
      nextLabel="Tjetra"
      containerClassName="products-pagination"
      pageClassName="products-pagination-link"
      nextClassName="products-pagination-link-next"
      previousClassName="products-pagination-link-previous"
      pageLinkClassName="products-pagination-a-link"
      activeClassName="products-pagination-link-active"
      activeLinkClassName="products-pagination-a-link-active"
      forcePage={currentPage - 1}
    />
  </div>
);

const mapStateToProps = state => ({
  totalPageCount: selectTotalNumberOfPages(state),
  currentPage: selectCurrentPage(state)
});

ShopComponent.propTypes = {
  totalPageCount: PropTypes.number,
  changePage: PropTypes.func,
  currentPage: PropTypes.number,
  match: PropTypes.object
};

export default connect(
  mapStateToProps,
  {
    changePage
  }
)(ShopComponent);
