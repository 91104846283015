import React from "react";

import "./Solutions.styles.css";
import SingleZone from "../../assets/img/single-zone.png";
import MultiZone from "../../assets/img/multi-zone.png";
import SolutionTemplate from "./SolutionTemplate";

const Solutions = () => (
  <div className="content">
    <section className="zoned-comfort-solutions">
      <div className="zoned-comfort-solutions__intro">
        <h2 className="zoned-comfort-solutions__title">
          Zgjidhjet që ofron Mitsubishi
        </h2>
        <hr className="zoned-comfort-solutions__title-divider" />
        <h4>
          Shikoni se çfarë ofron Mitsubishi Electric për ju dhe zgjidhni se
          çfarë ju përshtatet.
        </h4>
      </div>

      <div className="zoned-comfort-solutions__content">
        <ul className="zoned-comfort-solutions__nav">
          <li
            id="select1"
            className="zoned-comfort-solutions__nav-item active zoned-comfort-solutions__nav-item-button"
            style={{ cursor: "pointer" }}
            onMouseOver={() => {
              document.getElementById("select2").classList.remove("active");
              document.getElementById("select1").classList.add("active");
              document
                .querySelector(".zoned-comfort-solutions__cta-slider")
                .classList.remove("move-left");
            }}
          >
            Multi-Split
          </li>
          <li
            id="select2"
            className="zoned-comfort-solutions__nav-item zoned-comfort-solutions__nav-item-button"
            style={{ cursor: "pointer" }}
            onMouseOver={() => {
              document.getElementById("select1").classList.remove("active");
              document.getElementById("select2").classList.add("active");
              document
                .querySelector(".zoned-comfort-solutions__cta-slider")
                .classList.add("move-left");
            }}
          >
            Mono-Split
          </li>
        </ul>

        <div className="zoned-comfort-solutions__cta-slider-window">
          <div className="zoned-comfort-solutions__cta-slider">
            <SolutionTemplate
              imgSrc={MultiZone}
              buttonColor="rose"
              title="Multi-Split"
              container="Kontrolloni individualisht deri në tetë dhoma në shtëpinë tuaj nga një
      pajisje e jashtme e vetme me Mitsubishi Electric."
              buttonLink="https://www.youtube.com/watch?v=VmIosMrLNJ4"
            />

            <div className="zoned-comfort-solutions__cta-divider">&nbsp;</div>

            <SolutionTemplate
              imgSrc={SingleZone}
              buttonColor="success"
              title="Mono-split"
              container="Një pajisje e brendshme lidhet me një pajisje të jashtme pë të
                  siguruar komfortin e një zone të vetme."
              buttonLink="https://www.youtube.com/watch?v=FQEmU--sMX4"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Solutions;
