import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import "./ZonedComfort.styles.css";
import Button from "../CustomButtons/Button";

const ZonedComfort = ({ history }) => (
  <section className="buying-zoned-comfort">
    <div className="buying-zoned-comfort__content">
      <h2>Gjej një zgjidhje komode me Plus-Klima</h2>
      <hr className="buying-zoned-comfort__divider" />
      <h5>
        Merr më tepër informacion mbi tekonologjitë e reja dhe komfortin që
        ofrojnë pajisjet Mitsubishi
      </h5>
      <Button
        size="lg"
        color="rose"
        onClick={() => history.push("/produkte/seria-m")}
      >
        Shiko Produktet
      </Button>
    </div>
    <div className="buying-zoned-comfort__image-container">&nbsp;</div>
  </section>
);

ZonedComfort.propTypes = {
  history: PropTypes.object
};

export default withRouter(ZonedComfort);
