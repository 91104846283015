import React from "react";
import PropTypes from "prop-types";

import "./ProductsFilterOverlay.css";

const ProductsFilterOverlay = ({ children }) => (
  <div className="products-filter-wrapper">{children}</div>
);

ProductsFilterOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ProductsFilterOverlay;
