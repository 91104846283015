import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import {
  fetchProductsStartAsync,
  resetProductsToDefault
} from "../../redux/product/product.actions";

import {
  selectProductItems,
  selectIsLoaded
} from "../../redux/product/product.selectors";

import {
  selectCurrentPage,
  selectCapacity
} from "../../redux/filter/filter.selectors";

import ProductItemCard from "components/ProductItemCard/ProductItemCard";
import ProductsWrapperOverlay from "./ProductsWrapperOverlay";

import {
  selectSearchInput,
  selectColor,
  selectFamily,
  selectPriceFrom,
  selectPriceTo
} from "redux/filter/filter.selectors";
import { resetDefault } from "../../redux/filter/filter.actions";
import BigSpinner from "components/BigSpinner/BigSpinner";

class ProductsWrapper extends React.Component {
  fetch() {
    const {
      fetchProducts,
      match: { params },
      color,
      searchInput,
      priceFrom,
      priceTo,
      family,
      page,
      capacity
    } = this.props;

    fetchProducts(params.seria, {
      color,
      searchInput,
      priceFrom,
      priceTo,
      family,
      page,
      capacity
    });
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      color,
      searchInput,
      priceFrom,
      priceTo,
      family,
      resetFilterToDefault,
      page,
      capacity
    } = this.props;

    if (params.seria !== prevProps.match.params.seria) {
      resetFilterToDefault();
      this.fetch();
    }

    if (
      color !== prevProps.color ||
      searchInput !== prevProps.searchInput ||
      priceFrom !== prevProps.priceFrom ||
      priceTo !== prevProps.priceTo ||
      family !== prevProps.family ||
      page !== prevProps.page ||
      capacity !== prevProps.capacity
    ) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    const { resetProductsToDefault } = this.props;

    resetProductsToDefault();
  }

  render() {
    const { products, isLoading } = this.props;

    return isLoading ? (
      <ProductsWrapperOverlay>
        {products.length !== 0 ? (
          products.map(({ ...productProps }, index) => (
            <ProductItemCard {...productProps} key={index} />
          ))
        ) : (
          <p>Nuk ka produkte me filtrat e kërkuar</p>
        )}
      </ProductsWrapperOverlay>
    ) : (
      <ProductsWrapperOverlay>
        <BigSpinner />
      </ProductsWrapperOverlay>
    );
  }
}

const mapStateToProps = state => ({
  products: selectProductItems(state),
  isLoading: selectIsLoaded(state),
  color: selectColor(state),
  searchInput: selectSearchInput(state),
  family: selectFamily(state),
  priceFrom: selectPriceFrom(state),
  priceTo: selectPriceTo(state),
  page: selectCurrentPage(state),
  capacity: selectCapacity(state)
});

ProductsWrapper.propTypes = {
  fetchProducts: PropTypes.func,
  match: PropTypes.object,
  isLoading: PropTypes.bool,
  products: PropTypes.array,
  searchInput: PropTypes.string,
  priceFrom: PropTypes.number,
  priceTo: PropTypes.number,
  color: PropTypes.string,
  handleInputChange: PropTypes.func,
  selectColor: PropTypes.func,
  resetFilterToDefault: PropTypes.func,
  handleFamilyChange: PropTypes.func,
  fetchFamilies: PropTypes.func,
  family: PropTypes.array,
  page: PropTypes.number,
  capacity: PropTypes.array,
  resetProductsToDefault: PropTypes.func
};

export default compose(
  connect(
    mapStateToProps,
    {
      fetchProducts: fetchProductsStartAsync,
      resetFilterToDefault: resetDefault,
      resetProductsToDefault
    }
  ),
  withRouter
)(ProductsWrapper);
