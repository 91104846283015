import React from "react";
import PropTypes from "prop-types";

import "./TableDetails.css";

const TableDetails = ({ klasi_n, klasi_f, kapaciteti_n, kapaciteti_f }) => (
  <table className="table-data-modal" style={{ width: "100%" }}>
    <tbody>
      <tr>
        <td>Klasi në ngrohje:</td>
        <td>
          {klasi_n === "0" ? "Në varësi të pajisjes së jashtme" : klasi_n}
        </td>
      </tr>
      <tr>
        <td>Klasi në ftohje:</td>
        <td>
          {klasi_f === "0" ? "Në varësi të pajisjes së jashtme" : klasi_f}
        </td>
      </tr>
      <tr>
        <td>Kapaciteti në ngrohje (kW):</td>
        <td>{kapaciteti_n || "Në varësi të pajisjes së jashtme"}</td>
      </tr>
      <tr>
        <td>Kapaciteti në ftohje (kW):</td>
        <td>{kapaciteti_f || "Në varësi të pajisjes së jashtme"}</td>
      </tr>
    </tbody>
  </table>
);

TableDetails.propTypes = {
  klasi_n: PropTypes.string,
  klasi_f: PropTypes.string,
  kapaciteti_n: PropTypes.number,
  kapaciteti_f: PropTypes.number
};

export default TableDetails;
