const FilterActionTypes = {
  FETCH_FAMILIES_START: "FETCH_FAMILIES_START",
  FETCH_FAMILIES_SUCCESS: "FETCH_FAMILIES_SUCCESS",
  FETCH_FAMILIES_FAILURE: "FETCH_FAMILIES_FAILURE",
  CHANGE_INPUT: "CHANGE_INPUT",
  HANDLE_FAMILY_CHANGE: "HANDLE_FAMILY_CHANGE",
  SELECT_COLOR: "SELECT_COLOR",
  RESET_DEFAULT: "RESET_DEFAULT",
  CHANGE_PAGE: "CHANGE_PAGE",
  HANDLE_CAPACITY_CHANGE: "HANDLE_CAPACITY_CHANGE"
};

export default FilterActionTypes;
