import React from "react";
import PropTypes from "prop-types";

import CustomInput from "components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import EuroSymbol from "@material-ui/icons/EuroSymbol";

const PriceSection = ({ priceFrom, handleInputChange, priceTo }) => (
  <div>
    <p>Çmimi</p>
    <hr />
    <CustomInput
      labelText="Nga"
      id="material"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        name: "priceFrom",
        value: priceFrom,
        type: "number",
        onChange: event => handleInputChange(event),
        endAdornment: (
          <InputAdornment position="end">
            <EuroSymbol />
          </InputAdornment>
        )
      }}
    />
    <CustomInput
      labelText="Në"
      id="material"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        name: "priceTo",
        value: priceTo,
        type: "number",
        onChange: event => handleInputChange(event),
        endAdornment: (
          <InputAdornment position="end">
            <EuroSymbol />
          </InputAdornment>
        )
      }}
    />
  </div>
);

PriceSection.propTypes = {
  priceFrom: PropTypes.number,
  priceTo: PropTypes.number,
  handleInputChange: PropTypes.func
};
export default PriceSection;
