import querystring from "querystring";
import axios from "axios";

export default class HTTP {
  constructor() {
    this.baseURL = "https://d3bw4ixl1z5ei7.cloudfront.net";
  }

  handleError() {
    window.location.replace("/not-found");
  }

  get(url, query = {}) {
    let queryParam = querystring.stringify(query);
    if (queryParam) queryParam = `?${queryParam}`;
    return new Promise((resolve, reject) => {
      return axios
        .get(`${url}${queryParam}`)
        .then(res => {
          if (res.status >= 400) {
            return reject("Error");
          } else {
            return res;
          }
        })
        .then(res => (res.data ? res.data : res))
        .then(data => resolve(data))
        .catch(() => this.handleError());
    });
  }

  post(url, data = {}) {
    return axios
      .post(`${url}`, data)
      .then(res => (res.data ? res.data : res))
      .catch(this.handleError);
  }
}
