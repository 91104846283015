import React from "react";
import PropTypes from "prop-types";

import "./ProductDetailsOverlay.css";

const ProductDetailsOverlay = ({ children }) => (
  <div className="product-details-page">{children}</div>
);

ProductDetailsOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ProductDetailsOverlay;
