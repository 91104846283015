import React from "react";
import PropTypes from "prop-types";

import "./ProductsWrapperOverlay.css";

const ProductsWrapperOverlay = ({ children }) => (
  <div className="products-outer-wrapper">
    <div className="products-wrapper">{children}</div>
  </div>
);

ProductsWrapperOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ProductsWrapperOverlay;
