import React from "react";

import InfoArea from "components/InfoArea/InfoArea.js";

// SVGs
import LocationCity from "@material-ui/icons/LocationCity";
import Build from "@material-ui/icons/Build";
import Message from "@material-ui/icons/Message";

import "./InfoArea.css";

const InfoAreaSection = () => (
  <div className="infoarea-container">
    <InfoArea
      title="Shtrirje e gjerë"
      description='Plus-Klima ofron një gamë të gjerë produktesh "Mitsubishi". Përmes stafit tonë të përkushtuar mundësojmë instalime jo vetëm në Shqipëri, por dhe më gjerë.'
      icon={LocationCity}
    />
    <InfoArea
      title="Performancë e lartë"
      description="Përmes kualitetit rë produkteve Mitsubishi ne ofrojmë zgjidhjet më të mira sot në treg për sistemet e ngrohjes dhe ftohjes."
      icon={Build}
    />
    <InfoArea
      title="Përgjigje të menjëhershme"
      description="Plus-Klima ju ofron suport teknik të përhershëm. Mjafton të na kontaktoni në një nga format tona të kontaktit."
      icon={Message}
    />
  </div>
);

export default InfoAreaSection;
