import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import Spinner from "components/Spinner/Spinner";

import {
  handleFormChange,
  postFormStartAsync
} from "../../redux/form/form.actions";

import "./Form.css";

class Form extends React.Component {
  render() {
    const {
      handleFormChange,
      sendMail,
      mailData,
      name,
      lastname,
      number,
      body,
      email,
      errorMessage,
      sent,
      isPosting
    } = this.props;

    return (
      <div className="form-container">
        <br />
        <h3 className="form-container-title">Dërgo një e-mail</h3>
        <br />
        <form>
          <CustomInput
            labelText={
              errorMessage.email ? `${errorMessage.email}` : "E-mail *"
            }
            id="email"
            error={errorMessage.email ? true : false}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "email",
              value: email,
              type: "email",
              onChange: event => handleFormChange(event)
            }}
          />

          <CustomInput
            labelText="Emri"
            id="name"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "name",
              value: name,
              onChange: event => handleFormChange(event)
            }}
          />

          <CustomInput
            labelText="Mbiemri"
            id="lastname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "lastname",
              value: lastname,
              onChange: event => handleFormChange(event)
            }}
          />

          <CustomInput
            labelText="Numri i telefonit"
            id="number"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              name: "number",
              type: "number",
              value: number,
              onChange: event => handleFormChange(event)
            }}
          />

          <div>
            <label>
              {errorMessage.body ? (
                <span style={{ color: "red" }}>{errorMessage.body}</span>
              ) : (
                <span>Kërkesa *</span>
              )}
            </label>
            <textarea
              style={{
                border: "1px solid rgb(210, 210, 210)",
                padding: "10px 0 0 10px",
                width: "98%",
                resize: "vertical"
              }}
              rows={7}
              value={body}
              name="body"
              onChange={event => handleFormChange(event)}
            ></textarea>
          </div>

          <Button
            style={{ marginTop: "30px" }}
            onClick={event => {
              event.preventDefault();
              sendMail(mailData);
            }}
            disabled={isPosting ? true : false}
          >
            {isPosting ? <Spinner /> : "Dërgo"}
          </Button>
          {errorMessage.other ? (
            <span className="form-post-fail">{errorMessage.other}</span>
          ) : null}
          {sent ? (
            <span className="form-post-success">
              *Kërkesa juaj u dërgua me sukses
            </span>
          ) : null}
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    email,
    name,
    lastname,
    number,
    body,
    errorMessage,
    sent,
    isPosting
  } = state.form;
  return {
    mailData: { email, name, lastname, number, body },
    email,
    name,
    lastname,
    number,
    body,
    errorMessage,
    sent,
    isPosting
  };
};

Form.propTypes = {
  handleFormChange: PropTypes.func,
  sendMail: PropTypes.func,
  mailData: PropTypes.object,
  email: PropTypes.string,
  name: PropTypes.string,
  lastname: PropTypes.string,
  body: PropTypes.string,
  number: PropTypes.string,
  errorMessage: PropTypes.object,
  sent: PropTypes.string,
  isPosting: PropTypes.bool
};

export default connect(
  mapStateToProps,
  {
    handleFormChange,
    sendMail: postFormStartAsync
  }
)(Form);
