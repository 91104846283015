import React from "react";
import PropTypes from "prop-types";

import "./Benefits.css";

const Benefits = ({ data }) => (
  <div className="benefits-list">
    {data.map((result, index) => (
      <div className="benefit" key={index}>
        <span>
          <div className="benefit-wrapper">
            <div className="benefit-content">
              <h3>{result.title}</h3>
              <p>{result.subtitle}</p>
            </div>
          </div>
        </span>
      </div>
    ))}
  </div>
);

Benefits.propTypes = {
  data: PropTypes.array
};

export default Benefits;
