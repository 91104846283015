const ValidateForm = values => {
  return new Promise((resolve, reject) => {
    const errors = { other: undefined };
    if (!values.email) {
      errors.email = "E-mail është i detyrueshëm";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Adresë e-maili jo e saktë";
    }
    if (!values.body) {
      errors.body = "Është e nevojshme të shkruani një kërkesë!";
    }

    if (Object.keys(errors).length > 1) {
      reject(errors);
    } else {
      resolve(values);
    }
  });
};

export default ValidateForm;
