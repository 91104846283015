import React from "react";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";

import "./ColorPalette.css";

const ColorPalette = ({ colors, selectColor, selectedColor }) => (
  <div className="color-palette">
    {colors.map(({ color, tooltipText, styles }) => (
      <Tooltip key={color} placement="top" title={tooltipText}>
        <div
          className={`circle ${
            color === selectedColor ? "circle-outline" : ""
          }`}
          style={{ ...styles }}
          data-color={color}
          onClick={event => selectColor(event)}
        ></div>
      </Tooltip>
    ))}
  </div>
);

ColorPalette.propTypes = {
  colors: PropTypes.array,
  selectColor: PropTypes.func,
  selectedColor: PropTypes.string,
  styles: PropTypes.object
};

export default ColorPalette;
