const ProductActionTypes = {
  FETCH_PRODUCTS_START: "FETCH_PRODUCTS_START",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCTS_FAILURE: "FETCH_PRODUCTS_FAILURE",

  FETCH_PRODUCT_DETAILS_START: "FETCH_PRODUCT_DETAILS_START",
  FETCH_PRODUCT_DETAILS_SUCCESS: "FETCH_PRODUCT_DETAILS_SUCCESS",
  FETCH_PRODUCT_DETAILS_FAILURE: "FETCH_PRODUCT_DETAILS_FAILURE",

  RESET_PRODUCTS_TO_DEFAULT: "RESET_PRODUCTS_TO_DEFAULT"
};

export default ProductActionTypes;
