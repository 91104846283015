import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import ProductDeatilsTableSection from "./sections/ProductDetailsTableSection";
import GoBack from "./sections/GoBack";
import ImageAndInfoDetailsSection from "./sections/ImageAndInfoDetailsSection";
import OtherProducts from "./sections/OtherProducts";
import ProductDetailsOverlay from "./ProductDetailsOverlay";
import ImageAndInfoDetailsOverlay from "./ImageAndInfoDetailsOverlay";
import BigSpinner from "components/BigSpinner/BigSpinner";

import { fetchProductDetailsStartAsync } from "redux/product/product.actions";
import * as productSelectors from "redux/product/product.selectors";

class ProductDetails extends React.Component {
  fetch() {
    const {
      fetchProductDetails,
      match: { params }
    } = this.props;
    fetchProductDetails(params.seria, params.modeli);
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params }
    } = this.props;

    if (params.modeli !== prevProps.match.params.modeli) {
      this.fetch();
    }
  }

  render() {
    const {
      match: { params },
      similarProducts,
      history,
      isLoading,
      familyDetails,
      productDetails
    } = this.props;

    return isLoading ? (
      <ProductDetailsOverlay>
        <Helmet>
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta
            name="description"
            content="Pajisje murore Mitsubishi që ofrojnë performancën më të lartë. Kondicionerë me garanci 5-vjeçare. Produkte ju mundësojnë komfortin maksimal."
          />
          <meta
            name="keywords"
            content={`plus, klima, ${productDetails.kodi_b.toUpperCase()}, kondicionere multi, kondicionere te lire, mitsubishi tirane, mitsubishi electric, kondicionere, kondicionere mitsubishi electric tirana, kondicionere mitsubishi, kondicioner inverter, kondicioner mitsubishi, kondicioner te perdorur, kondicioner mitsubishi ne tirane, kondicioner per shitje, kondicionere tirane, uni, klima, kondicionere muror`}
          />
          <title>
            Kondicionerë Mitsubishi | {`${productDetails.kodi_b.toUpperCase()}`}{" "}
            | Plus-Klima
          </title>
        </Helmet>

        <GoBack history={history} params={params} />

        <ImageAndInfoDetailsSection
          productDetails={productDetails}
          familyDetails={familyDetails}
        />

        <ProductDeatilsTableSection productDetails={productDetails} />
        <OtherProducts similarProducts={similarProducts} />
      </ProductDetailsOverlay>
    ) : (
      <ProductDetailsOverlay>
        <GoBack history={history} params={params} />

        <ImageAndInfoDetailsOverlay>
          <BigSpinner />
        </ImageAndInfoDetailsOverlay>
      </ProductDetailsOverlay>
    );
  }
}

const mapStateToProps = state => ({
  productDetails: productSelectors.selectProductDetails(state),
  familyDetails: productSelectors.selectProductDescription(state),
  similarProducts: productSelectors.selectSimilarProducts(state),
  isLoading: productSelectors.selectIsProductDetailsLoaded(state)
});

ProductDetails.propTypes = {
  productDetails: PropTypes.object,
  match: PropTypes.object,
  fetchProductDetails: PropTypes.func,
  familyDetails: PropTypes.object,
  history: PropTypes.object,
  similarProducts: PropTypes.array,
  isLoading: PropTypes.bool
};

export default connect(
  mapStateToProps,
  {
    fetchProductDetails: fetchProductDetailsStartAsync
  }
)(ProductDetails);
