import React from "react";
import PropTypes from "prop-types";

import "./ProductsFilterBodyOverlay.css";

const ProductsFilterBodyOverlay = ({ children }) => (
  <div className="products-filter-body">{children}</div>
);

ProductsFilterBodyOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ProductsFilterBodyOverlay;
