import FilterActionTypes from "./filter.types";
import { DB } from "../../api/DB";

export const fetchFamiliesStart = () => ({
  type: FilterActionTypes.FETCH_FAMILIES_START
});

export const fetchFamiliesSuccess = families => ({
  type: FilterActionTypes.FETCH_FAMILIES_SUCCESS,
  payload: families
});

export const fetchFamiliesFailure = errorMessage => ({
  type: FilterActionTypes.FETCH_FAMILIES_FAILURE,
  payload: { error: errorMessage }
});

export const fetchFamiliesStartAsync = serie => {
  return dispatch => {
    dispatch(fetchFamiliesStart());

    DB.Product.getFamilies(serie)
      .then(families => dispatch(fetchFamiliesSuccess(families)))
      .catch(err => dispatch(fetchFamiliesFailure(err)));
  };
};

export const handleInputChange = event => {
  let value;
  if (event.target.name === "priceTo" && !event.target.value) {
    value = 0;
  } else {
    value = event.target.value;
  }
  return {
    type: FilterActionTypes.CHANGE_INPUT,
    payload: {
      target: event.target.name,
      value
    }
  };
};

export const handleColorSelect = event => ({
  type: FilterActionTypes.SELECT_COLOR,
  payload: event.target.dataset.color
});

export const resetDefault = () => ({
  type: FilterActionTypes.RESET_DEFAULT,
  payload: {
    searchInput: "",
    color: "",
    priceFrom: 0,
    priceTo: 4300,
    family: [],
    page: 1,
    capacity: []
  }
});

export const handleFamilyChange = event => ({
  type: FilterActionTypes.HANDLE_FAMILY_CHANGE,
  payload: event.target.dataset.id
});

export const changePage = page => ({
  type: FilterActionTypes.CHANGE_PAGE,
  payload: page
});

export const handleCapacityChange = event => ({
  type: FilterActionTypes.HANDLE_CAPACITY_CHANGE,
  payload: event.target.dataset.id
});
