import FilterActionTypes from "./filter.types";

const INITIAL_STATE = {
  families: null,
  isFetching: false,
  errorMessage: null,
  loaded: false,
  searchInput: "",
  color: "",
  priceFrom: 0,
  priceTo: 4300,
  family: [],
  page: 1,
  capacity: []
};

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FilterActionTypes.FETCH_FAMILIES_START:
      return {
        ...state,
        isFetching: true,
        loaded: false
      };
    case FilterActionTypes.FETCH_FAMILIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        loaded: true,
        families: action.payload
      };
    case FilterActionTypes.FETCH_FAMILIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        loaded: false,
        errorMessage: action.payload
      };
    case FilterActionTypes.CHANGE_INPUT:
      return {
        ...state,
        page: 1,
        [action.payload.target]: action.payload.value
      };
    case FilterActionTypes.SELECT_COLOR:
      return {
        ...state,
        page: 1,
        color: action.payload
      };
    case FilterActionTypes.RESET_DEFAULT:
      return {
        ...state,
        ...action.payload
      };
    case FilterActionTypes.HANDLE_FAMILY_CHANGE:
      return {
        ...state,
        page: 1,
        family:
          state.family.indexOf(action.payload) !== -1
            ? state.family.filter(f => f !== action.payload)
            : [...state.family, action.payload]
      };

    case FilterActionTypes.CHANGE_PAGE:
      return {
        ...state,
        page: action.payload
      };
    case FilterActionTypes.HANDLE_CAPACITY_CHANGE:
      return {
        ...state,
        page: 1,
        capacity:
          state.capacity.indexOf(action.payload) !== -1
            ? state.capacity.filter(c => c !== action.payload)
            : [...state.capacity, action.payload]
      };
    default:
      return state;
  }
};

export default filterReducer;
