import React from "react";
import PropTypes from "prop-types";

import "./TimelineItem.scss";

const TimelineItem = ({ year, content, imgSrc, dataText }) => (
  <div className="timeline-item" data-text={dataText}>
    <div className="timeline__content">
      <img className="timeline__img" src={imgSrc} alt="timeline-item" />
      <h2 className="timeline__content-title">{year}</h2>
      <p className="timeline__content-desc">{content}</p>
    </div>
  </div>
);

TimelineItem.propTypes = {
  year: PropTypes.string,
  content: PropTypes.string,
  imgSrc: PropTypes.string,
  dataText: PropTypes.string
};

export default TimelineItem;
