import { createSelector } from "reselect";

const selectProducts = state => state.products;

export const selectProductItems = createSelector(
  [selectProducts],
  products => products.products
);

export const selectTotalNumberOfPages = createSelector(
  [selectProducts],
  products => Math.ceil(products.totalResultsFromQuery / 9)
);

export const selectIsFetching = createSelector(
  [selectProducts],
  products => products.isFetching
);

export const selectIsProductsLoaded = createSelector(
  [selectProducts],
  products => {
    return !!products.products && !products.products.length;
  }
);

export const selectProductDetails = createSelector(
  [selectProducts],
  products =>
    products.productDetails !== null ? products.productDetails[0][0] : null
);

export const selectProductDescription = createSelector(
  [selectProducts],
  products =>
    products.familyDescription !== null ? products.familyDescription[0] : null
);

export const selectSimilarProducts = createSelector(
  [selectProducts],
  products =>
    products.productDetails !== null ? products.productDetails[1] : null
);

export const selectIsLoaded = createSelector(
  [selectProducts],
  products => products.loaded
);

export const selectIsProductDetailsLoaded = createSelector(
  [selectProducts],
  products => products.productDetailsLoaded
);
