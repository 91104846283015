import React from "react";
import PropTypes from "prop-types";

import Button from "../../components/CustomButtons/Button";

import "./SolutionTemplate.css";

const SolutionTemplate = ({
  imgSrc,
  title,
  container,
  buttonLink,
  buttonColor
}) => (
  <div className="zoned-comfort-solutions__cta-container">
    <img
      src={imgSrc}
      alt="Zone Icon"
      className="zoned-comfort-solutions__cta-img"
    />
    <h3 className="zoned-comfort-solutions__cta-container-title">
      <span className="zoned-comfort-solutions__cta-container-title-1">
        Zgjidhjet
      </span>
      <span className="zoned-comfort-solutions__cta-container-title-2">
        {title}
      </span>
    </h3>
    <hr className="zoned-comfort-solutions__cta-container-title-divider" />
    <h5 className="zoned-comfort-solutions__cta-container-copy">{container}</h5>
    <Button
      size="lg"
      color={buttonColor}
      href={buttonLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      Shiko si
    </Button>
  </div>
);

SolutionTemplate.propTypes = {
  imgSrc: PropTypes.string,
  buttonColor: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "facebook",
    "twitter",
    "google",
    "github",
    "transparent"
  ]),
  buttonLink: PropTypes.string,
  container: PropTypes.string,
  title: PropTypes.string
};

export default SolutionTemplate;
