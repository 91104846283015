import { combineReducers } from "redux";

import serieReducer from "./serie/serie.reducer";
import formReducer from "./form/form.reducer";
import productReducer from "./product/product.reducer";
import filterReducer from "./filter/filter.reducers";

const rootReducer = combineReducers({
  series: serieReducer,
  form: formReducer,
  products: productReducer,
  filter: filterReducer
});

export default rootReducer;
