import React from "react";
import PropTypes from "prop-types";

import ProductItemCard from "components/ProductItemCard/ProductItemCard";

import "./OtherProducts.css";

const OtherProducts = ({ similarProducts }) => (
  <div>
    <h3 style={{ margin: "20px 30px" }}>Produkte të tjera</h3>
    <hr />

    <div className="similar-products">
      {similarProducts.map(({ ...productProps }, index) => (
        <ProductItemCard {...productProps} key={index} />
      ))}
    </div>
    <div style={{ height: "20px" }}> </div>
  </div>
);

OtherProducts.propTypes = {
  similarProducts: PropTypes.array
};

export default OtherProducts;
