import ProductActionTypes from "./product.types";

const INITIAL_STATE = {
  products: null,
  errorMessage: null,
  isFetching: false,
  loaded: false,
  totalResultsFromQuery: 0,
  isProductDetailsFetching: false,
  productDetailsErrorMessage: null,
  productDetailsLoaded: false,
  familyDescription: null,
  productDetails: null
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductActionTypes.FETCH_PRODUCTS_START:
      return {
        ...state,
        isFetching: true,
        loaded: false
      };
    case ProductActionTypes.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        loaded: true,
        products: action.payload[0],
        totalResultsFromQuery: action.payload[1][0].totalResultsFromQuery
      };
    case ProductActionTypes.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        loaded: false,
        errorMessage: action.payload
      };

    case ProductActionTypes.FETCH_PRODUCT_DETAILS_START:
      return {
        ...state,
        isProductDetailsFetching: true,
        productDetailsLoaded: false
      };
    case ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        isProductDetailsFetching: false,
        productDetails: action.payload,
        familyDescription: action.description,
        productDetailsLoaded: true
      };
    case ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        isProductDetailsFetching: false,
        productDetailsErrorMessage: action.payload,
        productDetailsLoaded: false
      };
    case ProductActionTypes.RESET_PRODUCTS_TO_DEFAULT:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default productReducer;
