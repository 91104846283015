import React from "react";
import PropTypes from "prop-types";

import ColorPalette from "components/ColorPalette/ColorPalette";

const ColorSection = ({ selectColor, color }) => (
  <div>
    <p>Ngjyra</p>
    <hr />
    <ColorPalette
      colors={[
        {
          color: "white",
          tooltipText: "E Bardhë",
          styles: {
            background:
              "linear-gradient(0deg, rgb(255,255,255), rgb(240,240,240))"
          }
        },
        {
          color: "black",
          tooltipText: "E Zezë/Oniks",
          styles: {
            background: "linear-gradient(0deg, rgb(80,80,80), rgb(0,0,0))"
          }
        },
        {
          color: "silver",
          tooltipText: "Gri/Silver",
          styles: {
            background:
              "linear-gradient(0deg, rgb(200,200,200), rgb(156,156,156))"
          }
        },
        {
          color: "red",
          tooltipText: "E Kuqe/Rubin",
          styles: {
            background: "linear-gradient(0deg, #d6314a,#a8273b)"
          }
        }
      ]}
      selectColor={selectColor}
      selectedColor={color}
    />
  </div>
);

ColorSection.propTypes = {
  color: PropTypes.string,
  selectColor: PropTypes.func
};

export default ColorSection;
