import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";

import canUseWebP from "helpers/canUseWebP";

import Benefits from "components/Benefits/Benefits";
import Background from "components/Background/Background";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/components.js";

import "./technology-page.css";

const useStyles = makeStyles(styles);

const TechnologyPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Kondicionere Mitsubishi me garanci 5-vjeçare. Teknologjia më e fundit Mitsubishi vjen në duart tuaja. Me pajisjet më të fuqishme jo do të keni komfortin maksimal."
        />
        <meta
          name="keywords"
          content="kondicionere, kondicionere mitsubishi electric tirana, kondicionere mitsubishi, kondicioner inverter, kondicioner mitsubishi, kondicioner te perdorur, kondicioner mitsubishi ne tirane, montim, servis kondicioneresh, kondicioner per shitje, kondicionere tirane, uni, klima, kondicionere muror, kondicionere dysheme, kondicionere tavanor, kondicionere te mire, globe kondicioner, kondicioner, teknologjia mitsubishi, kondicionere neptun"
        />
        <title>
          Kondicionerë Mitsubishi | Teknologjia Mitsubishi | Plus-Klima{" "}
        </title>
      </Helmet>
      <Background
        imgSrc={
          canUseWebP()
            ? require("assets/img/webp/background_2.webp")
            : require("assets/img/background_2.jpg")
        }
        otherClasses="banner"
        title="Përfitoni kualitetin më të lartë."
        subtitle="Mitsubishi Electric sjell teknologjinë më të fundit për të
                  siguruar rehatinë maksimale."
        titleStyles={{ color: "rgb(120, 120, 120)" }}
        subtitleStyles={{ color: "rgb(100, 100, 100)" }}
        button
      />

      <div
        style={{ width: "96vw", margin: "-70px auto" }}
        className={classNames(classes.main, classes.mainRaised)}
      >
        <div className="benefits-tagline benefits-main-masthead">
          <h2>Zgjidhje komforte me Mitsubishi Electric</h2>
          <h4 className="info-subtitle">
            Ne ju mundësojmë të gjitha avantazhet që sistemet e tjera
            tradicionale të kondicionimit nuk i ofrojnë
          </h4>
        </div>

        <Benefits
          data={[
            {
              title: "KUALITETI I AJRIT",
              subtitle:
                "Tekonologjia e re e filtrimit të ajrit mundëson një ambient të brendshëm të pastër dhe të shëndetshëm."
            },
            {
              title: "EFIÇECË",
              subtitle:
                "Sistemet Mitsubishi kursejnë 40% më tepër sesa sistemet e tjera të ajërimit dhe ngrohjes."
            },
            {
              title: "PERFOMANCË E LARTË NË NGROHJE",
              subtitle: `Tekonologjia e re mundëson ngrohjen e ambientit tuaj edhe kur temperatura e ambientit të jashtëm arrin deri në -25℃.`
            },
            {
              title: "QETËSI E LARTË",
              subtitle:
                "Sistemet Mitsubishi përshtaten për të krijuar komfortin në ambientin tuaj."
            },
            {
              title: "KONTROLLE SMART",
              subtitle:
                "Sensorët e rinj mundësojnë kontrollin përmes Wi-Fi apo aplikacioneve edhe kur ju nuk ndodheni në shtëpi."
            }
          ]}
        />
      </div>
    </div>
  );
};

export default TechnologyPage;
