import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";

import "./Background.css";
import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

const Background = ({
  imgSrc,
  otherClasses,
  title,
  subtitle,
  titleStyles,
  subtitleStyles,
  button,
  history
}) => {
  const classes = useStyles();
  return (
    <Parallax image={imgSrc}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <div className={`${classes.brand} ${otherClasses}`}>
              <h2 style={{ ...titleStyles, fontWeight: 400 }}>{title}</h2>
              <h4 style={{ ...subtitleStyles }}>{subtitle}</h4>

              {button ? (
                <Button
                  style={{ marginTop: "20px" }}
                  onClick={() => history.push("/produkte/seria-m")}
                >
                  Shiko Pajisjet
                </Button>
              ) : null}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Parallax>
  );
};

Background.propTypes = {
  imgSrc: PropTypes.string,
  otherClasses: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleStyles: PropTypes.object,
  subtitleStyles: PropTypes.object,
  button: PropTypes.bool,
  history: PropTypes.object
};

export default withRouter(Background);
