import FormActionTypes from "./form.types";

const INITIAL_STATE = {
  email: "",
  name: "",
  lastname: "",
  number: "",
  body: "",
  isPosting: false,
  errorMessage: { email: undefined, body: undefined, other: undefined },
  sent: undefined
};

const formReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FormActionTypes.CHANGE_FORM_INPUT:
      return {
        ...state,
        [action.payload.target]: action.payload.value
      };

    case FormActionTypes.POST_FORM_START:
      return {
        ...state,
        isPosting: true,
        sent: undefined,
        errorMessage: { email: undefined, body: undefined, other: undefined }
      };
    case FormActionTypes.POST_FORM_SUCCESS:
      return {
        ...state,
        isPosting: false,
        sent: action.payload
      };
    case FormActionTypes.POST_FORM_FAILURE:
      return {
        ...state,
        isPosting: false,
        errorMessage: action.payload
      };

    default:
      return {
        ...state
      };
  }
};

export default formReducer;
