import React from "react";
import $ from "jquery";

import TimelineItem from "./TimelineItem/TimelineItem";
import timelineData from "./TimelineData";

import "./Timeline.scss";

class Timeline extends React.Component {
  componentDidMount() {
    $.fn.timeline = function() {
      var selectors = {
        id: $(this),
        item: $(this).find(".timeline-item"),
        activeClass: "timeline-item--active",
        img: ".timeline__img"
      };
      selectors.item.eq(0).addClass(selectors.activeClass);

      var itemLength = selectors.item.length;
      $(window).scroll(function() {
        var max, min;
        var pos = $(this).scrollTop();
        selectors.item.each(function(i) {
          min = $(this).offset().top;
          max = $(this).height() + $(this).offset().top;
          if (i === itemLength - 2 && pos > min + $(this).height() / 2) {
            selectors.item.removeClass(selectors.activeClass);

            selectors.item.last().addClass(selectors.activeClass);
          } else if (pos <= max - 40 && pos >= min) {
            selectors.item.removeClass(selectors.activeClass);
            $(this).addClass(selectors.activeClass);
          }
        });
      });
    };

    $("#timeline-1").timeline();
  }

  render() {
    return (
      <div style={{ margin: "0 30px" }}>
        <div className="timeline-container" id="timeline-1">
          <div className="timeline-header">
            <h2 className="timeline-header__title">
              Një rrugëtim i shkurtër mbi historinë e kompanisë
            </h2>
          </div>
          <div className="timeline">
            {timelineData.map((timelineItem, index) => (
              <TimelineItem
                imgSrc={timelineItem.imgSrc}
                content={timelineItem.content}
                year={timelineItem.year}
                dataText={timelineItem.dataText}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
