import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Modal from "@material-ui/core/Modal";
import ProductDetailsImage from "components/ProductDetailsImage/ProductDetailsImage";
import Button from "components/CustomButtons/Button";
import TableDetails from "components/TableDetails/TableDetails";
import Tooltip from "@material-ui/core/Tooltip";

import "./ProductItemCard.scss";
import canUseWebP from "helpers/canUseWebP";

class ProductItemCard extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const {
      kodi_b,
      cmimi_set,
      cmimi_tek_ms,
      imazhi,
      match,
      history
    } = this.props;

    return (
      <div className="product-wrapper">
        <div className="product-container">
          <div
            className="product-top"
            style={
              canUseWebP()
                ? {
                    background: `url(${process.env.PUBLIC_URL +
                      "/" +
                      imazhi.split("/")[1] +
                      "/webp/" +
                      imazhi
                        .replace(".png", ".webp")
                        .split("/")[2]}) center center / 100% no-repeat`,
                    height: "80%",
                    width: "100%"
                  }
                : {
                    background: `url(${process.env.PUBLIC_URL +
                      imazhi}) center center / 100% no-repeat`,
                    height: "80%",
                    width: "100%"
                  }
            }
            onClick={() =>
              history.push(
                `/produkte/${match.params.seria}/${kodi_b.toLowerCase()}`
              )
            }
          ></div>
          <div className="product-bottom">
            <div className="product-left">
              <div className="product-details">
                <h1 style={{ fontSize: "21px" }}>{kodi_b}</h1>
                <h4>{cmimi_set || cmimi_tek_ms} €</h4>
              </div>
              <Tooltip
                id="tooltip-top"
                title="Shikim i shpejtë"
                placement="top"
              >
                <div className="buy" onClick={this.handleOpen}>
                  <i className="material-icons">remove_red_eye</i>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="inside">
          <div className="icon">
            <i className="material-icons">info_outline</i>
          </div>
          <div className="contents">
            <TableDetails {...this.props} />
          </div>
        </div>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className="product-item-modal">
            <ProductDetailsImage
              imgSrc={
                canUseWebP()
                  ? `${process.env.PUBLIC_URL +
                      "/" +
                      imazhi.split("/")[1] +
                      "/webp/" +
                      imazhi.replace(".png", ".webp").split("/")[2]}`
                  : process.env.PUBLIC_URL + imazhi
              }
            />
            <div style={{ width: "80%" }}>
              <h3>{kodi_b}</h3>
              <h4>
                <b>Çmimi: {cmimi_set || cmimi_tek_ms} €</b>
              </h4>
              <TableDetails {...this.props} />
              <Button
                onClick={() =>
                  this.setState({ open: false }, () => {
                    history.push(
                      `/produkte/${match.params.seria}/${kodi_b.toLowerCase()}`
                    );
                  })
                }
                style={{ marginTop: "30px", float: "right" }}
                color="rose"
              >
                Shiko më tepër detaje
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ProductItemCard.propTypes = {
  kodi_b: PropTypes.string,
  cmimi_set: PropTypes.number,
  imazhi: PropTypes.string,
  cmimi_tek_ms: PropTypes.number,
  match: PropTypes.object,
  history: PropTypes.object
};

export default withRouter(ProductItemCard);
