import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import PropTypes from "prop-types";

import FilterTitle from "./filterComponentSections/FilterTitle";
import FilterSearch from "./filterComponentSections/FilterSearch";
import PriceSection from "./filterComponentSections/PriceSection";
import CategorySection from "./filterComponentSections/CategorySection";
import CapacitySection from "./filterComponentSections/CapacitySection";
import ColorSection from "./filterComponentSections/ColorSection";
import ClearFiltersSection from "./filterComponentSections/ClearFiltersSection";

import ProductsFilterOverlay from "./ProductsFilterOverlay";
import ProductsFilterBodyOverlay from "./ProductsFilterBodyOverlay";

import * as filterActions from "../../redux/filter/filter.actions";
import * as filterSelectors from "../../redux/filter/filter.selectors";

import "./commonStyles.css";

class ProductsFilter extends React.Component {
  fetch() {
    const {
      fetchFamilies,
      match: { params }
    } = this.props;

    fetchFamilies(params.seria);
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      resetFilterToDefault
    } = this.props;

    if (
      params.seria !== prevProps.match.params.seria ||
      params.page !== prevProps.match.params.page
    ) {
      resetFilterToDefault();
      this.fetch();
    }
  }

  componentWillUnmount() {
    const { resetFilterToDefault } = this.props;
    resetFilterToDefault();
  }

  render() {
    const {
      resetFilterToDefault,
      searchInput,
      priceFrom,
      priceTo,
      isLoading,
      families,
      family,
      reduxStateCapacity,
      color,
      handleInputChange,
      handleFamilyChange,
      handleCapacityChange,
      selectColor
    } = this.props;

    return (
      <ProductsFilterOverlay>
        <FilterTitle />
        <ProductsFilterBodyOverlay>
          <FilterSearch
            handleInputChange={handleInputChange}
            searchInput={searchInput}
          />
          <PriceSection
            priceFrom={priceFrom}
            handleInputChange={handleInputChange}
            priceTo={priceTo}
          />
          <CategorySection
            isLoading={isLoading}
            families={families}
            family={family}
            handleFamilyChange={handleFamilyChange}
          />
          <CapacitySection
            reduxStateCapacity={reduxStateCapacity}
            handleCapacityChange={handleCapacityChange}
          />
          <ColorSection selectColor={selectColor} color={color} />
          <ClearFiltersSection resetFilterToDefault={resetFilterToDefault} />
        </ProductsFilterBodyOverlay>
      </ProductsFilterOverlay>
    );
  }
}

const mapStateToProps = state => ({
  families: filterSelectors.selectFamiliesItems(state),
  searchInput: filterSelectors.selectSearchInput(state),
  priceFrom: filterSelectors.selectPriceFrom(state),
  priceTo: filterSelectors.selectPriceTo(state),
  color: filterSelectors.selectColor(state),
  family: filterSelectors.selectFamily(state),
  reduxStateCapacity: filterSelectors.selectCapacity(state),
  isLoading: filterSelectors.isFamilyLoaded(state),
  currentPage: filterSelectors.selectCurrentPage(state)
});

ProductsFilter.propTypes = {
  families: PropTypes.array,
  searchInput: PropTypes.string,
  priceFrom: PropTypes.number,
  priceTo: PropTypes.number,
  color: PropTypes.string,
  handleInputChange: PropTypes.func,
  selectColor: PropTypes.func,
  resetFilterToDefault: PropTypes.func,
  handleFamilyChange: PropTypes.func,
  fetchFamilies: PropTypes.func,
  match: PropTypes.object,
  handleCapacityChange: PropTypes.func,
  family: PropTypes.array,
  reduxStateCapacity: PropTypes.array,
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number
};

export default compose(
  connect(
    mapStateToProps,
    {
      fetchFamilies: filterActions.fetchFamiliesStartAsync,
      selectColor: filterActions.handleColorSelect,
      resetFilterToDefault: filterActions.resetDefault,
      handleFamilyChange: filterActions.handleFamilyChange,
      handleCapacityChange: filterActions.handleCapacityChange,
      handleInputChange: filterActions.handleInputChange
    }
  ),
  withRouter
)(ProductsFilter);
