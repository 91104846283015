import HTTP from "./HTTP";

export default class Product extends HTTP {
  constructor() {
    super();

    this.apiPath = "/produkte";
    this.url = this.getURL();
  }

  getURL() {
    return `${this.baseURL}${this.apiPath}`;
  }

  getSeries(seria) {
    return this.get(`${this.url}/${seria}`);
  }

  getFamilies(serie) {
    return this.get(`${this.url}/familje/${serie}`);
  }

  getFilteredProducts(seria, queryStrings) {
    return this.get(`${this.url}/${seria}`, { ...queryStrings });
  }

  getProductDetails(seria, model) {
    return this.get(`${this.url}/${seria}/${model}`);
  }

  getFamilyDescription(queryStrings) {
    return this.get(`${this.url}/pershkrimi`, { ...queryStrings });
  }
}
