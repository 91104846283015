import React from "react";
import Fade from "react-reveal/Fade";

import Remote from "assets/img/remote.jpg";
import RemoteWebP from "assets/img/webp/remote.webp";
import canUseWebP from "helpers/canUseWebP";

import "./InfoImage.css";

const InfoImageSection = () => (
  <div className="info-image">
    <Fade bottom>
      <img
        src={canUseWebP() ? RemoteWebP : Remote}
        className="image-info"
        alt="remote"
      />
    </Fade>
    <div>
      <div className="info-image-text">
        <Fade bottom>
          <h3 style={{ marginTop: "0px", paddingTop: "0px" }}>
            Dizajn kompakt
          </h3>
          <p>Prerje të reja që përshtaten me dekorin tuaj.</p>
        </Fade>

        <Fade bottom>
          <h3>Performancë e lartë</h3>
          <p>Teknologji moderne që lejon ngrohjen deri në -25°C.</p>
        </Fade>

        <Fade bottom>
          <h3>Kursim energjie A+++</h3>
          <p>
            Kurseni deri në 40% më shumë energji me sistemet e reja Mitsubishi
            Electric.
          </p>
        </Fade>
      </div>
    </div>
  </div>
);

export default InfoImageSection;
