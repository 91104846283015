import ProductActionTypes from "./product.types";
import { DB } from "../../api/DB";

const fetchProductsStart = () => ({
  type: ProductActionTypes.FETCH_PRODUCTS_START
});

const fetchProductsSuccess = products => ({
  type: ProductActionTypes.FETCH_PRODUCTS_SUCCESS,
  payload: products
});

const fetchProductsFailure = errorMessage => ({
  type: ProductActionTypes.FETCH_PRODUCTS_FAILURE,
  payload: { error: errorMessage }
});

export const fetchProductsStartAsync = (serie, queryStrings) => {
  return dispatch => {
    dispatch(fetchProductsStart());

    DB.Product.getFilteredProducts(serie, queryStrings)
      .then(products => dispatch(fetchProductsSuccess(products)))
      .catch(err => dispatch(fetchProductsFailure(err)));
  };
};

const fetchProductDetailsStart = () => ({
  type: ProductActionTypes.FETCH_PRODUCT_DETAILS_START
});

const fetchProductDetailsSuccess = (product, description) => ({
  type: ProductActionTypes.FETCH_PRODUCT_DETAILS_SUCCESS,
  payload: product,
  description
});

const fetchProductDetailsFailure = errorMessage => ({
  type: ProductActionTypes.FETCH_PRODUCT_DETAILS_FAILURE,
  payload: errorMessage
});

export const fetchProductDetailsStartAsync = (seria, model) => {
  return dispatch => {
    dispatch(fetchProductDetailsStart());

    DB.Product.getProductDetails(seria, model)
      .then(values => {
        DB.Product.getFamilyDescription({
          familyId: values[0][0].familja_id
        }).then(desc => dispatch(fetchProductDetailsSuccess(values, desc)));
      })
      .catch(err => dispatch(fetchProductDetailsFailure(err)));
  };
};

export const resetProductsToDefault = () => ({
  type: ProductActionTypes.RESET_PRODUCTS_TO_DEFAULT,
  payload: { loaded: false, products: null }
});
