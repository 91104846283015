import React from "react";
import PropTypes from "prop-types";

import Back from "@material-ui/icons/KeyboardBackspace";

const GoBack = ({ history, params }) => (
  <div
    style={{
      padding: "30px 0 10px 30px",
      cursor: "pointer",
      display: "inline-block"
    }}
    onClick={() => history.push(`/produkte/${params.seria}`)}
  >
    <Back fontSize="large" />
  </div>
);

GoBack.propTypes = {
  history: PropTypes.object,
  params: PropTypes.object
};

export default GoBack;
