/*eslint-disable*/
import React from "react";

import { Link } from "react-router-dom";

import "./Footer.css";

export default function Footer(props) {
  return (
    <footer>
      <div className="container-fluid">
        <div className="footer-row">
          <div>
            <h5 style={{ color: "white" }}>
              <b>FORMAT E KONTAKTIT</b>
            </h5>
            <p className="text-white">
              Cel:{" "}
              <span>
                <a id="cel_number" href="tel:+355692181069">
                  +355 69 21 81 069
                </a>
              </span>
            </p>

            <p className="text-white">
              Email:{" "}
              <span>
                <a id="email" href="mailto: mitsubishialb@live.com">
                  mitsubishialb@live.com
                </a>
              </span>
            </p>
            <div>
              <a
                href="https://www.facebook.com/plusklimaKondicionere"
                target="_blank"
                className="footer-link"
              >
                <i
                  className="fab fa-facebook"
                  style={{ marginRight: "10px" }}
                />
                <span style={{ color: "#ded1c1", fontSize: "16px" }}>
                  Kondicionerë Mitsubishi Plus-Klima
                </span>
              </a>
            </div>

            <div style={{ marginTop: "10px" }}>
              <a
                href="https://www.instagram.com/plus_klima"
                target="_blank"
                className="footer-link"
              >
                <i
                  className="fab fa-instagram"
                  style={{ marginRight: "10px", color: "#fb3958" }}
                />
                <span style={{ color: "#ded1c1", fontSize: "16px" }}>
                  plus_klima
                </span>
              </a>
            </div>
          </div>

          <div>
            <ul className="footer-list">
              <li>
                <h5 className="text-white">
                  <b>MENU</b>
                </h5>
              </li>
              <li>
                <Link to="/">Faqja kryesore</Link>
              </li>
              <li>
                <Link to="/rreth-nesh">Rreth Nesh</Link>
              </li>
              <li>
                <Link to="/produkte/seria-m">Produkte</Link>
              </li>
              <li>
                <Link to="/teknologjia-mitsubishi">Teknologjia Mitsubishi</Link>
              </li>
              <li>
                <Link to="/kontakt">Kontakt</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        © {1900 + new Date().getYear()} Copyright:
        <Link className="footer-link" to="/">
          {" "}
          www.plus-klima.com
        </Link>
      </div>
    </footer>
  );
}
